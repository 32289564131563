@font-face {
    font-family: Legrand;
    src: url("./assets/fonts/legrand.ttf");
}

.legrand {
    font-family: Legrand;
}

body, html {
    font-family: 'Della Respira', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #801e34;
}

p {
    margin: 0;
}

h5 { line-height: 1rem;
}

a {
    color: #801e34;
    display: inline-block;
    position: relative;
    line-height: 3rem;
    z-index: 1;
}

.qa-question {
    margin-top: 64px;
}
.qa-question p {
    line-height: 1rem;
}

.menu-item:hover {
    color: #801e34;
}

.menu-item:hover:after {
    content: "";
    display: block;
    bottom: 0;
    right: -16px;
    left: -16px;
    top: 4px;
    position: absolute;
    background-color: rgba(93, 7, 14, 0.21);
    z-index: -1;
    border-radius: 50%;
}

.sidelined {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 8px 0px;
}

.sidelined::after, .sidelined::before {
    content: "";
    width: calc(50% - 50px);
    height: 4px;
    background-color: #80070e;
    display: block;
    position: absolute;
}

.sidelined::before {
    left: 0;
    margin-right: 15px;
}

.sidelined::after {
    right: 0;
    margin-left: 15px;
}

.menu-header {
    margin: 32px 0px 4px 0px;
    font-size: 20px;
}

.menu-header.sidelined::after, .menu-header.sidelined::before {
    width: calc(50% - 150px);
}

.dinner-menu {
    padding: 0;
}

.dinner-menu li {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
}

.table-header {
    font-size: 20px;
}
